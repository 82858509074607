<template>
  <router-link class="link" :to="generateLink(card, type)">
  <!-- <router-link class="link" :to="`/music/${getMusicSlug(card)}`"> -->
    <div :title="(card.disabled && type === 'videos') ? 'Premium Content' : ''">
      <div class="card-wrapper">
        <b-overlay
          :show="card.disabled && type === 'videos'"
          :opacity="0.5"
          variant="dark"
          rounded
          no-center>
          <template #overlay>
            <b-badge
              class="position-absolute premium-badge"
              variant="primary"
              v-if="card.disabled && type === 'videos'"
              style="top: 7%; left: 5%;">
              Premium
            </b-badge>
            <i class="fas fa-lock" style="color: transparent;"></i>
          </template>
          <img
            class="card-carousel-card-thumbnail"
            :class="{
              'card-border' : cardBorder,
            }"
            :src="card.thumbnail"
            :alt="card.name"
            :style="{
              width : `${cardWidthStyle}px`,
              height : `${cardHeightStyle}px`}"
          />
        </b-overlay>
      </div>
      <p class="card-name" v-if="showTitle">
        {{ card.name | truncate }}
      </p>
    </div>
  </router-link>
</template>

<script>
  import FilterMixin from '@/mixins/FilterMixin';
  import PremiumMixin from '@/mixins/PremiumMixin';

  export default {
    name   : 'AppCard',
    mixins : [
      FilterMixin,
      PremiumMixin,
    ],
    props : {
      card : {
        type     : Object,
        required : true,
      },
      columnItems : {
        type     : Number,
        required : true,
      },
      cardCarouselWidth : {
        type     : Number,
        required : true,
      },
      cardBorder : {
        type    : Boolean,
        default : false,
      },
      squareCards : {
        type    : Boolean,
        default : false,
      },
      showTitle : {
        type    : Boolean,
        default : false,
      },
      type : {
        type     : String,
        required : false,
      },
    },
    computed : {
      cardWidthStyle() {
        const gap = 18; // left and right margin 9px ea
        return (this.cardCarouselWidth / this.columnItems) - gap;
      },
      cardHeightStyle() {
        if (this.squareCards)
          return this.cardWidthStyle ? this.cardWidthStyle * 0.9 : 0;
        
        //16:10 ratio
        return this.cardWidthStyle ? this.cardWidthStyle * 0.625 : 0;
      },
    },
    methods : {
      action(data) {
        console.log('9898988989899889');
        if (this.type === 'playlist')
          this.$emit('openSongList', data);
        else if (this.type === 'videos')
          this.checkContent(data, 'video');
        else if (this.type === 'album')
          this.$emit('openAlbumSongs', data);
      },
      getMusicSlug(card) {
        console.log(card);
        return card.albumId || card.id;
      },
      generateAlbumSlug(albumName) {
        return generateSlug(albumName);
      },
      generateSlug(text) {
        return text
          .toString()
          .toLowerCase()
          .replace(/\s+/g, '-')           // Reemplaza espacios con -
          .replace(/[^\w-]+/g, '')        // Elimina caracteres no deseados
          .replace(/--+/g, '-')           // Reemplaza múltiples - con uno solo
          .replace(/^-+/, '')             // Elimina - al inicio
          .replace(/-+$/, '');            // Elimina - al final
      },
      // generateLink(album) {
      //   const slug = this.generateSlug(album.name);
      //   console.log(slug);
      //   return { name: 'MusicSelectedPage', params: { slug: slug, id: album.id } };
      // }
      generateLink(album, type) {
        const slug = this.generateSlug(album.name);
        return { 
          name: 'MusicSelectedPage', 
          params: { 
            type: type,
            slug: slug, 
            id: album.albumId || album.id ,
            album: album
          } 
        };
      }

      
    },

  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .premium-badge {
    position: absolute;
    background-color: $primary-color !important;
  }

  .card-wrapper {
    height: auto;
    border-radius: 10px;
    background-color: rgba(255,255,255,0.5);
    &:hover {
      background-color: #000;
    }
  }
  .card-carousel-card-thumbnail {
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    transition: opacity 150ms linear;
    user-select: none;
    vertical-align: bottom;

    &:hover {
      opacity: 0.7;
    }
  }

  .card-border {
    border: 2px solid #025CCD;
  }

  .card-name {
    color: #025CCD;
    font-size: 1.5em !important;
    text-align: left;
  }
</style>

